<template>
    <div class="section">
        <spinner v-if="!requestFinish"></spinner>
        <div v-if="requestFinish">
            <page-title class="ml-2" :title="$t('sst.pages.oneIntervention.title', { id: data.intervention_request })"></page-title>
            <b-container>
                <b-card no-body class="mb-3">
                    <b-card-header header-bg-variant="white">
                        <b-card-title title-tag="h5" class="mt-1 mb-0" v-if="data.reason">{{ data.reason }}</b-card-title>
                        <dropdown-group
                            @clickDropdownOption="clickDropdownOptionGroupBtn"
                            :dropdownGroupParams="dropdownGroupParams"
                            class="card-header-actions"
                        ></dropdown-group>
                        <image-uploader
                            :maxWidth="fileConstants.MAX_WIDTH"
                            :quality="fileConstants.QUALITY"
                            :autoRotate="true"
                            outputFormat="verbose"
                            :preview="false"
                            :capture="false"
                            doNotResize="['gif', 'svg', 'pdf']"
                            @change="uploadFile"
                            class="d-none"
                            ref="file"
                            id="fileInput"
                        ></image-uploader>
                    </b-card-header>
                    <b-card-body>
                        <b-card-text>
                            <div class="contact-line" v-if="data.site">
                                <b-icon icon="geo-alt" class="icon text-primary"></b-icon>
                                <a :href="openGoogleMaps()" target="_blank" class="text-muted"
                                    >{{ data.site.name }} {{ data.site.address_1 }} {{ data.site.address_2 }} {{ data.site.zip_code }}
                                    {{ data.site.city }}
                                    <span v-if="data.site.country !== countryConstants.FRANCE">{{ data.site.country }}</span>
                                </a>
                            </div>
                            <div class="contact-line" v-if="data.site.phone_number">
                                <b-icon icon="phone" class="icon text-primary"></b-icon>
                                <a :href="'tel:' + data.site.phone_number" class="text-muted">
                                    <span>{{ data.site.phone_number }}</span>
                                </a>
                            </div>
                        </b-card-text>
                    </b-card-body>
                </b-card>

                <b-card-group columns>
                    <sst-intervention-document-section
                        v-if="data.quotation"
                        :documentType="interventionConstants.DOCUMENT_TYPES.QUOTATION"
                        :document="data.quotation"
                        :purchaseId="purchaseId"
                        :userIsGuest="isGuest"
                    ></sst-intervention-document-section>

                    <sst-intervention-document-section
                        v-if="data.bi_document"
                        :documentType="interventionConstants.DOCUMENT_TYPES.BI"
                        :document="data.bi_document"
                        :purchaseId="purchaseId"
                        :userIsGuest="isGuest"
                    ></sst-intervention-document-section>

                    <sst-intervention-document-section
                        v-if="data.conformity_certificate"
                        :documentType="interventionConstants.DOCUMENT_TYPES.ACE"
                        :document="data.conformity_certificate"
                        :purchaseId="purchaseId"
                        :userIsGuest="isGuest"
                    ></sst-intervention-document-section>

                    <sst-intervention-document-section
                        v-if="data.invoice"
                        :documentType="interventionConstants.DOCUMENT_TYPES.INVOICE"
                        :document="data.invoice"
                        :purchaseId="purchaseId"
                        :userIsGuest="isGuest"
                    ></sst-intervention-document-section>

                    <sst-intervention-image-section
                        :imageType="interventionConstants.DOCUMENT_TYPES.PICS_BEFORE"
                        :images="data.pictures.pre_intervention"
                    ></sst-intervention-image-section>

                    <sst-intervention-image-section
                        :imageType="interventionConstants.DOCUMENT_TYPES.PICS_AFTER"
                        :images="data.pictures.post_intervention"
                    ></sst-intervention-image-section>
                </b-card-group>
            </b-container>
        </div>
    </div>
</template>

<script>
import PageTitle from '@/layouts/components/PageTitle.vue';
import { SstRepository } from '@/repositories';
import { INTERVENTION } from '@/constants/sst/intervention';
import { COUNTRY } from '@/constants/shared/country';
import SstInterventionImageSection from '@/components/sst/intervention/SstInterventionImageSection';
import SstInterventionDocumentSection from '@/components/sst/intervention/SstInterventionDocumentSection';
import Spinner from '@/components/shared/element/Spinner';
import DropdownGroup from '@/components/shared/element/DropdownGroup';
import { FILE, TABLE } from '@/constants';
import FileService from '@/services/FileService';
import FileHelper from '@/utils/fileHelper';
import { GuestSstRepository } from '@/repositories';
import store from '@/store';
import router from '@/router';

export default {
    name: 'SstInterventionShow',
    components: {
        PageTitle,
        SstInterventionImageSection,
        SstInterventionDocumentSection,
        Spinner,
        DropdownGroup
    },
    data() {
        // let groupOptionsDownloadArray = [
        //     {
        //         id: INTERVENTION.VOUCHER.CONSULTATION,
        //         name: this.$t('sst.pages.oneIntervention.downloadDropdown.options.consultation')
        //     },
        //     {
        //         id: INTERVENTION.VOUCHER.INTERVENTION,
        //         name: this.$t('sst.pages.oneIntervention.downloadDropdown.options.intervention')
        //     },
        //     {
        //         id: INTERVENTION.VOUCHER.ORDER,
        //         name: this.$t('sst.pages.oneIntervention.downloadDropdown.options.order')
        //     }
        // ];

        return {
            data: [],
            requestFinish: false,
            interventionConstants: INTERVENTION,
            fileConstants: FILE,
            countryConstants: COUNTRY,
            dropdownGroupParams: {
                title: this.$t('sst.pages.oneIntervention.groupDropdown.labels.actions'),
                disabled: false,
                right: true,
                size: 'sm',
                variant: 'link',
                groupOptions: [
                    {
                        title: this.$t('sst.pages.oneIntervention.groupDropdown.labels.upload'),
                        id: INTERVENTION.ACTIONS.UPLOAD,
                        options: []
                    },
                    {
                        title: this.$t('sst.pages.oneIntervention.groupDropdown.labels.download'),
                        id: INTERVENTION.ACTIONS.DOWNLOAD,
                        options: []
                    }
                ]
            },
            documentTypeUpload: null,
            isGuest: store.getters['auth/isGuestSstUser']
        };
    },
    created() {
        this.getData();
    },
    computed: {
        purchaseId() {
            if (this.isGuest) {
                return Number(this.$route.params.id);
            } else if (this.data) {
                return this.data.id;
            }

            return this.$route.params.id;
        }
    },
    methods: {
        getData() {
            let repository = SstRepository;
            if (this.isGuest) {
                repository = GuestSstRepository;
            }
            repository
                .getPurchase(this.$route.params.id)
                .then(response => {
                    this.requestFinish = true;
                    this.data = response.data.data;
                    this.getUploadOptions();
                    this.getDownloadOptions();
                })
                .catch(error => {
                    this.requestFinish = true;
                    this.$toast.error(this.$t('httpErrors.404.info'));
                    if (this.isGuest) {
                        router.push({ name: 'Login' });
                    } else {
                        router.push({ name: 'SstInterventionsListe' });
                    }
                    console.log(error);
                });
        },
        openGoogleMaps() {
            const destination =
                this.data.site.address_1 +
                '+' +
                this.data.site.address_2 +
                '+' +
                this.data.site.zip_code +
                '+' +
                this.data.site.city +
                '+' +
                this.data.site.country;
            if (
                navigator.platform.indexOf('iPhone') !== -1 ||
                navigator.platform.indexOf('iPod') !== -1 ||
                navigator.platform.indexOf('iPad') !== -1
            ) {
                return 'maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=' + destination;
            } else {
                return 'https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=' + destination;
            }
        },
        getDownloadOptions() {
            this.dropdownGroupParams.groupOptions[1].options = [];

            // if key 'description' is missing, the option "Télécharger: Bon d'intervention" is not accessible
            if (this.data.description) {
                this.dropdownGroupParams.groupOptions[1].options.push({
                    id: INTERVENTION.VOUCHER.INTERVENTION,
                    name: this.$t('sst.pages.oneIntervention.downloadDropdown.options.intervention')
                });
            }

            // if key 'consulting_memo' is missing, the option "Télécharger: Bon de consultation" is not accessible
            if (this.data.consulting_memo) {
                this.dropdownGroupParams.groupOptions[1].options.push({
                    id: INTERVENTION.VOUCHER.CONSULTATION,
                    name: this.$t('sst.pages.oneIntervention.downloadDropdown.options.consultation')
                });
            }

            // this.dropdownGroupParams.groupOptions[1].options.push({
            //     id: INTERVENTION.VOUCHER.ORDER,
            //     name: this.$t('sst.pages.oneIntervention.downloadDropdown.options.order')
            // });

            // if there are no options, delete the group, so, in this way, not will display the options group title
            if (this.dropdownGroupParams.groupOptions[1].options.length === 0) {
                this.$delete(this.dropdownGroupParams.groupOptions, 1);
            }
        },
        getUploadOptions() {
            this.dropdownGroupParams.groupOptions[0].options = [];

            if (
                !this.data.pictures.pre_intervention ||
                (this.data.pictures.pre_intervention &&
                    this.data.pictures.pre_intervention.filter(item => item.document.validation.validated === true).length +
                        this.data.pictures.pre_intervention.filter(
                            item => item.document.validation.validated === false && item.document.validation.cancellation_reason === null
                        ).length <
                        INTERVENTION.SELECT_BOX_CONDITIONS.PICTURES)
            ) {
                this.dropdownGroupParams.groupOptions[0].options.push({
                    id: INTERVENTION.UPLOAD_DOCUMENT_TYPES.PICS_BEFORE,
                    name: this.$t('sst.pages.oneIntervention.uploadDropdown.options.PICS_BEFORE')
                });
            }

            if (
                !this.data.quotation ||
                (this.data.quotation.validation.validated === false && this.data.quotation.validation.cancellation_reason > 0)
            ) {
                this.dropdownGroupParams.groupOptions[0].options.push({
                    id: INTERVENTION.UPLOAD_DOCUMENT_TYPES.QUOTATION,
                    name: this.$t('sst.pages.oneIntervention.uploadDropdown.options.QUOTATION')
                });
            }

            if (
                [1, 21].includes(this.data.skill_id) &&
                (!this.data.conformity_certificate ||
                    (this.data.conformity_certificate.validation.validated === false &&
                        this.data.conformity_certificate.validation.cancellation_reason > 0))
            ) {
                this.dropdownGroupParams.groupOptions[0].options.push({
                    id: INTERVENTION.UPLOAD_DOCUMENT_TYPES.CONFORMITY_CERTIFICATE,
                    name: this.$t('sst.pages.oneIntervention.uploadDropdown.options.CONFORMITY_CERTIFICATE')
                });
            }

            // if key 'description' is missing, the option "Envoyer: Bon d'intervention" and "Facture" are not accessible
            if (
                this.data.description &&
                (!this.data.bi_document ||
                    (this.data.bi_document.validation.validated === false && this.data.bi_document.validation.cancellation_reason > 0))
            ) {
                this.dropdownGroupParams.groupOptions[0].options.push({
                    id: INTERVENTION.UPLOAD_DOCUMENT_TYPES.BI,
                    name: this.$t('sst.pages.oneIntervention.uploadDropdown.options.BI')
                });
            }

            if (
                this.data.description &&
                (!this.data.invoice ||
                    (this.data.invoice.validation.validated === false && this.data.invoice.validation.cancellation_reason > 0))
            ) {
                this.dropdownGroupParams.groupOptions[0].options.push({
                    id: INTERVENTION.UPLOAD_DOCUMENT_TYPES.INVOICE,
                    name: this.$t('sst.pages.oneIntervention.uploadDropdown.options.INVOICE')
                });
            }

            if (
                !this.data.pictures.post_intervention ||
                (this.data.pictures.post_intervention &&
                    this.data.pictures.post_intervention.filter(item => item.document.validation.validated === true).length +
                        this.data.pictures.post_intervention.filter(
                            item => item.document.validation.validated === false && item.document.validation.cancellation_reason === null
                        ).length <
                        INTERVENTION.SELECT_BOX_CONDITIONS.PICTURES)
            ) {
                this.dropdownGroupParams.groupOptions[0].options.push({
                    id: INTERVENTION.UPLOAD_DOCUMENT_TYPES.PICS_AFTER,
                    name: this.$t('sst.pages.oneIntervention.uploadDropdown.options.PICS_AFTER')
                });
            }

            // if there are no options, delete the group, so, in this way, not will display the options group title
            if (this.dropdownGroupParams.groupOptions[0].options.length === 0) {
                this.$delete(this.dropdownGroupParams.groupOptions, 1);
            }
        },
        clickDropdownOptionGroupBtn({ optionId, optionGroupId }) {
            if (optionGroupId === INTERVENTION.ACTIONS.UPLOAD) {
                this.openFileUpload(optionId);
            } else {
                this.downloadFile(optionId);
            }
        },
        downloadFile(option) {
            this.disableDropdown(true);
            let downloadUrl = TABLE.PAGE.SST.INTERVENTION.VOUCHER_PDF_URL;
            if (this.isGuest) {
                downloadUrl = TABLE.PAGE.GUEST_SST.INTERVENTION.VOUCHER_PDF_URL;
            }
            const apiDownload = downloadUrl.replace('{purchaseId}', this.purchaseId).replace('{documentType}', option);
            const fileName = this.voucherFilename(option).replace('{interventionRequestId}', this.data.intervention_request);
            FileService.downloadFile(apiDownload, fileName)
                .then(link => {
                    link.click();
                    this.disableDropdown(false);
                })
                .catch(() => {
                    this.$toast.error(this.$i18n.t('shared.components.customTable.notifications.errors.pdfNotFound'));
                    this.disableDropdown(false);
                });
        },
        openFileUpload(option) {
            this.documentTypeUpload = option;
            let fileInputElement = document.getElementById('fileInput');
            if (
                this.documentTypeUpload === INTERVENTION.UPLOAD_DOCUMENT_TYPES.PICS_AFTER ||
                this.documentTypeUpload === INTERVENTION.UPLOAD_DOCUMENT_TYPES.PICS_BEFORE
            ) {
                fileInputElement.accept = 'image/*';
            } else {
                fileInputElement.accept = 'image/*,application/pdf';
            }
            fileInputElement.click();
        },
        uploadFile(event) {
            const formData = new FormData();
            let file = event instanceof File ? event : FileHelper.dataURLtoFile(event.dataUrl, event.info.name);
            formData.append('document_type', this.documentTypeUpload);
            formData.append('document', file);
            this.disableDropdown(true);
            let repository = SstRepository;
            if (this.isGuest) {
                repository = GuestSstRepository;
            }
            repository
                .uploadDocument(this.purchaseId, formData)
                .then(() => {
                    this.disableDropdown(false);
                    this.getData();
                    this.$toast.success(this.$i18n.t('sst.pages.oneIntervention.uploadDropdown.notifications.success'));
                })
                .catch(error => {
                    console.log(error);
                    this.disableDropdown(false);
                    this.$toast.success(this.$i18n.t('sst.pages.oneIntervention.uploadDropdown.notifications.error'));
                });
        },
        voucherFilename(option) {
            switch (option) {
                case INTERVENTION.VOUCHER.CONSULTATION:
                    return 'bon_de_consultation_{interventionRequestId}.pdf';
                case INTERVENTION.VOUCHER.ORDER:
                    return 'bon_de_commande_{interventionRequestId}.pdf';
                case INTERVENTION.VOUCHER.INTERVENTION:
                    return 'bon_de_intervention_{interventionRequestId}.pdf';
            }
        },
        disableDropdown(disabled = true) {
            this.dropdownGroupParams.disabled = disabled;
        }
    }
};
</script>
<style lang="scss" scoped>
.contact-line {
    padding-left: 40px;
    position: relative;

    .icon {
        display: inline-block;
        width: 30px;
        position: absolute;
        left: 0;
        top: 2px;
    }
}

.card-header {
    padding-right: 85px;
    position: relative;

    &-actions {
        position: absolute;
        right: 6px;
        top: 12px;
    }
}
</style>
